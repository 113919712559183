import React from "react";
import cx from "classnames";

export const Article: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
	className,
	...props
}) => {
	return (
		<article
			className={cx(
				"prose prose-sm lg:prose-base max-w-[700px] text-white",
				"prose-headings:text-white",
				"prose-h3:text-xl prose-h4:text-lg lg:prose-h4:text-xl",
				"prose-h3:italic prose-h4:text-notice prose-a:underline",
				"prose-a:text-notice prose-a:break-all",
				className
			)}
			{...props}
		/>
	);
};
