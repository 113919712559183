import React, { Fragment, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import { Tab, Disclosure, Listbox, Transition } from "@headlessui/react";
import { ReactComponent as ArrowDown } from "icons/triangle-down.svg";
import { SectionHeading } from "components/SectionHeading";
import { Article } from "components/Article";

export default function FAQ({ data }: PageProps<Queries.FAQQuery>) {
	const { t } = useTranslation();
	const [selectedIndex, setSelectedIndex] = useState(0);
	const tabs = [
		data.installation,
		data.account,
		data.payment,
		data.game,
		data.miscellaneous,
	];

	// noinspection AllyJsxHardcodedStringInspection
	return (
		<div className="pt-12 pb-24 text-white lg:pt-20 lg:pb-72">
			<div>
				<SectionHeading className="justify-center mb-12 w-full lg:mb-28">
					{t("title.faq")}
				</SectionHeading>
				<div className="px-4 py-16 mx-auto max-w-5xl lg:py-32 bg-article-pattern">
					<Listbox value={selectedIndex} onChange={setSelectedIndex}>
						<div className={cx("text-2xl", "relative mb-16 lg:hidden")}>
							<Listbox.Button className="min-w-[243px] pl-4 mx-auto flex items-center justify-center cursor-default rounded-lg bg-white text-black h-[70px]">
								<span className="block text-center truncate">
									{tabs[selectedIndex]?.frontmatter?.title}
								</span>
								<ArrowDown width={39} height={33} aria-hidden="true" />
							</Listbox.Button>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-[29px] bg-white text-black shadow-lg divide-y divide-divider">
									{tabs.map((tab, idx) => (
										<Listbox.Option key={tab?.id} className="" value={idx}>
											<span className="block py-5 text-center truncate">
												{tab?.frontmatter?.title}
											</span>
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</Listbox>
					<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
						<Tab.List className="hidden flex-wrap justify-around mb-32 lg:flex">
							{tabs.map((tab) => (
								<Tab key={tab?.id} as={Fragment}>
									{({ selected }) => (
										<button
											type="button"
											className={cx(
												"font-bold px-6 py-2.5 text-2xl leading-none mb-4",
												{
													"text-black bg-white": selected,
												}
											)}
										>
											{tab?.frontmatter?.title}
										</button>
									)}
								</Tab>
							))}
						</Tab.List>
						<Tab.Panels className="border-white border-t-[5px]">
							{tabs.map((tab) => (
								<Tab.Panel key={tab?.id}>
									{tab?.fields?.questions?.map((q) => (
										<Disclosure key={q?.question}>
											{({ open }) => (
												<div className="border-b-[5px] border-white">
													<Disclosure.Button className="flex items-center py-8 w-full text-2xl font-bold text-left">
														<span className="grow">{q?.question}</span>
														<ArrowDown
															width={39}
															height={33}
															className={cx("ml-4 shrink-0", {
																"transform -rotate-180": open,
															})}
														/>
													</Disclosure.Button>
													<Disclosure.Panel className="pb-24">
														<Article
															className="!max-w-full"
															dangerouslySetInnerHTML={{
																__html: q?.answer ?? "",
															}}
														/>
													</Disclosure.Panel>
												</div>
											)}
										</Disclosure>
									))}
								</Tab.Panel>
							))}
						</Tab.Panels>
					</Tab.Group>
				</div>
			</div>
		</div>
	);
}

export const query = graphql`
	fragment FAQNode on MarkdownRemark {
		id
		frontmatter {
			title
		}
		fields {
			questions {
				answer
				question
			}
		}
	}
	query FAQ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					...Translation
				}
			}
		}
		account: markdownRemark(
			frontmatter: { type: { eq: "faq" }, slug: { eq: "Account" } }
			fields: { language: { eq: $language } }
		) {
			...FAQNode
		}
		installation: markdownRemark(
			frontmatter: { type: { eq: "faq" }, slug: { eq: "Installation" } }
			fields: { language: { eq: $language } }
		) {
			...FAQNode
		}
		game: markdownRemark(
			frontmatter: { type: { eq: "faq" }, slug: { eq: "Game" } }
			fields: { language: { eq: $language } }
		) {
			...FAQNode
		}
		payment: markdownRemark(
			frontmatter: { type: { eq: "faq" }, slug: { eq: "Payment" } }
			fields: { language: { eq: $language } }
		) {
			...FAQNode
		}
		miscellaneous: markdownRemark(
			frontmatter: { type: { eq: "faq" }, slug: { eq: "Miscellaneous" } }
			fields: { language: { eq: $language } }
		) {
			...FAQNode
		}
	}
`;

export { Head } from "components/GCSRewriteURL";
